// your main JavaScript file

// imports --------------------------------------------------------------------
import * as Vars from './0-vars';
import * as Listeners from './1-listeners.js';
import * as Once from './2-once.js';
import * as Page from './3-page.js';
import * as Resize from './4-resize.js';
import * as Scroll from './5-scroll.js';
import * as Load from './6-load.js';

// exported variables ---------------------------------------------------------

// local variables ------------------------------------------------------------

// console.log('This is the main JavaScript file.');

