// do these things only once
// even if you are using a history.js navigation

// imports --------------------------------------------------------------------
import * as Vars from './0-vars.js';
import * as Once from './2-once.js';
import * as Resize from './4-resize.js';
import * as Scroll from './5-scroll.js';
import * as MyHistory from './hr-history-v2.0.js';


window.Once = Once;
// exported variables ---------------------------------------------------------

// local variables ------------------------------------------------------------

// listeners function ---------------------------------------------------------
const listeners = () => {
  // console.log('listeners');
  
  // add mobile hover classes
  const hoverSelector = 'button, a, input';
  document.body.addEventListener('touchstart', event => {
    let el = elFromEvent(hoverSelector, event);
    if (el) el.classList.add('hover');
  });

  document.body.addEventListener('touchend', event => {
    let el = elFromEvent(hoverSelector, event);
    if (el) el.classList.remove('hover');
  });

  // click listener --------------------------------
  document.body.addEventListener('click', event => {
    // remove focus after click
    let el = elFromEvent('button, a, input[type="radio"], input[type="checkbox"], *[role="button"], input[type=range]', event);
    if (el && event.clientX !== 0 && event.clientY !== 0) el.blur();

    el = elFromEvent('.hamburger', event);
    if (el) {
      document.body.classList.toggle('body--nav');
      Scroll.checkBlockScroll();
    }

    el = elFromEvent('.toggle__btn:not([data-target-fold-outs])', event);
    if (el) {
      const toggleEl = el.closest('.toggle');
      if (toggleEl) toggleEl.classList.toggle('toggle--off');
    }

    // show more interests
    el = elFromEvent('.js-more-interests', event);
    if (el) {
      const interestsHolderEl = document.querySelector('.interests-holder');
      if(!interestsHolderEl) return;

      interestsHolderEl.classList.add('interests-holder--full');

      const buttonsEl = el.closest('.buttons');
      setTimeout(() => {
        buttonsEl.remove();
      }, Vars.transitionSlow);
    }

    // how buttons
    el = elFromEvent('.how .button', event);
    if (el) {
      // get data-target attribute
      const howEl = el.closest('.how');

      if (!howEl) return;
      const howButtons = howEl.querySelectorAll('.how .button');
      howButtons.forEach(button => {
        button.classList.remove('button--active');
      });

      el.classList.add('button--active');
    }

    // category btn
    el = elFromEvent('*[data-category]', event);
    if (el) {
      const termEls = document.querySelectorAll('.term');
      if (termEls.length === 0) return;

      const category = el.getAttribute('data-category');
      // console.log(category);

      setTimeout(() => {
        termEls.forEach(termEl => {
          termEl.classList.remove('term--visible');
        });

        const visibleTermsEls = document.querySelectorAll(`.term[data-categories*="${category}"]`);
        visibleTermsEls.forEach(termEl => {
          termEl.classList.add('term--visible');
        });
      }, Vars.transitionDefault);
    }

    // term foldout
    el = elFromEvent('.term__button', event);
    if (el) {
      const termEl = el.closest('.term');
      if (!termEl) return;

      const termTextEl = termEl.querySelector('.term__content');
      if (!termTextEl) return;

      if (termTextEl.classList.contains('term__content--active')) {
        termTextEl.classList.remove('term__content--active');

        setTimeout(() => {
          termTextEl.classList.remove('term__content--no-clamp');
        }, Vars.transitionDefault);
      } else {
        termTextEl.classList.add('term__content--no-clamp');
        termTextEl.classList.add('term__content--active');
      }
    }

    // foldout stuff ---------------------------------------------------------
    const animatingBtn = document.querySelector('button[data-fold-out][data-animating="true"]');

    // general foldouts
    el = elFromEvent('*[data-target-fold-outs]', event);
    if (el) {
      let dataTargetFoldouts = el.getAttribute('data-target-fold-outs');
      if (!dataTargetFoldouts) return;
      // console.log(dataTargetFoldouts);

      // split data-target-fold-out attribute on ,
      dataTargetFoldouts = dataTargetFoldouts.split(',');
      // console.log(dataTargetFoldouts);

      for (let i = 0; i < dataTargetFoldouts.length; i++) {
        const foldoutNr = dataTargetFoldouts[i];
        const btnEl = document.querySelector(`button[data-fold-out="${foldoutNr}"]`);

        // console.log({foldoutNr, btnEl});

        if (!btnEl || animatingBtn) {
          // don't animate
          return;
        }

        // check toggle switch
        const toggleEl = el.closest('.toggle');
        if (i == 0 && toggleEl) {
          toggleEl.classList.toggle('toggle--off');
        } 

        btnEl.click(); // this checks animating too
      }
    } 

    // radio buttons with foldout
    el = elFromEvent('input[type="radio"][data-target-fold-out]', event);
    if (el) {
      const foldoutNr = el.getAttribute('data-target-fold-out');
      const btnEl = document.querySelector('button[data-fold-out="' + foldoutNr + '"]');

      // console.log({foldoutNr, btnEl});

      if (!btnEl || animatingBtn) {
        // don't set radio button or animate
        event.preventDefault();
        return;
      } 

      const open = btnEl.dataset.open;
      const isOpen = btnEl.getAttribute('class').indexOf('active') !== -1;
      const containsOpen = open.indexOf(el.value) !== -1;
      // console.log({open, isOpen, containsOpen});

      if ((containsOpen && !isOpen)||
          (!containsOpen && isOpen)) {
        // console.log('click');
        btnEl.click(); // this checks animating too
      }
    }

    // checkbox with foldout
    el = elFromEvent('input[type="checkbox"][data-target-fold-out]', event);
    if (el) {
      const foldoutNr = el.getAttribute('data-target-fold-out');
      const btnEl = document.querySelector('button[data-fold-out="' + foldoutNr + '"]');

      if (!btnEl || animatingBtn) {
        // don't set checkbox or animate
        event.preventDefault();
        return;
      } 

      if (
        (!el.checked && btnEl.getAttribute('class').indexOf('active') !== -1) ||
        (el.checked && btnEl.getAttribute('class').indexOf('active') == -1)) {
        btnEl.click(); // this checks animating too
      }
    }
  });

  // change listener --------------------------------
  const changeHandler = event => {
    // console.log('changeHandler', event);
    // load page when changing these selects
    let el = elFromEvent('.label--period .label__select, .label--energy .label__select, .label--banks .label__select', event);
    if (el) {
      const pageTypeNext = el.getAttribute('data-pagetype-next');
      if (pageTypeNext) document.body.setAttribute('data-pagetype-next', pageTypeNext);

      const option = el.options[el.selectedIndex];
      const href = option.getAttribute('data-href');
      if (href)  {
        window.history.pushState(null, MyHistory.historyProjectTitle, href);
        MyHistory.historyInitLoadPage();
      }
    }

    // switch rates
    el = elFromEvent('.js-label-type, .js-label-energy', event);
    if (el) setBankRates();

    el = elFromEvent('select[name="afspraak-locatie"]', event);
    if (el) {
      const nr = el.selectedIndex - 1;
      const switcherBtnEl = document.querySelector(`button[data-switcher-target="address"][data-nr="${nr}"]`);
      if (switcherBtnEl) switcherBtnEl.click();
    }
  };

  document.body.addEventListener('change', changeHandler);

  // input handler
  const inputHandler = event => {
    // console.log('inputHandler', event);
    // check error classes for changes
    let el = elFromEvent('.error input', event);
    if (el) {
      el.closest('.error').classList.remove('error');

      // remove error from other radio/checkbox button with the same name
      const name = el.getAttribute('name');
      const radioEls = document.querySelectorAll(`input[type="radio"][name="${name}"]`);
      radioEls.forEach(radioEl => {
        const radioErrorEl = radioEl.closest('.error');
        if (radioErrorEl) radioErrorEl.classList.remove('error');
      });
    }
  }

  document.body.addEventListener('input', inputHandler);

  // mouseover, focusin listeners --------------------------------
  const swiperHoverSelector = '.swiper-pagination-bullet, .swiper-button-next, .swiper-button-prev';
  const mouseoverFocusinHandler = event => {
    // let el = elFromEvent(swiperHoverSelector, event);
    // if (el && 'aim' in Once) {
    //   Once.aim.classList.add('animate-it-mouse--hover');
    // }

    // console.log(event.target);

    // el = elFromEvent('.swiper-wrapper', event);
    // if (el && 'aim' in Once) {
    //   Once.aim.classList.add('animate-it-mouse--drag-hor');
    // }
  };

  document.body.addEventListener('mouseover', mouseoverFocusinHandler);
  document.body.addEventListener('focusin', mouseoverFocusinHandler);

  // focusin listener --------------------------------
  const focusinHandler = event => {
    let el = elFromEvent('input[data-type="number"]', event);
    if (el) {
      let value = el.value;

      // remove dots again
      value = value.replace(/\./g, '');
      el.value = value;
    }

    const navLangEl = document.querySelector('.nav__lang');
    if (navLangEl) {
      if (event.target.closest('.nav__lang')) {
        navLangEl.classList.add('nav__lang--active');
      } else {
        navLangEl.classList.remove('nav__lang--active');
      }
    }
  };

  document.body.addEventListener('focusin', focusinHandler);

  // mouseout, focusout listeners --------------------------------
  const mouseoutFocusoutHandler = event => {
    // let el = elFromEvent(swiperHoverSelector, event);
    // if (el && 'aim' in Once) {
    //   Once.aim.classList.remove('animate-it-mouse--hover');
    // }

    // el = elFromEvent('.swiper-wrapper', event);
    // if (el && 'aim' in Once) {
    //   Once.aim.classList.remove('animate-it-mouse--drag-hor');
    // }
  };

  document.body.addEventListener('mouseout', mouseoutFocusoutHandler);
  document.body.addEventListener('focusout', mouseoutFocusoutHandler);

  // focusout listener --------------------------------
  const focusoutHandler = event => {
    let el = elFromEvent('input[data-type="number"]', event);
    if (el) {
      let value = el.value;

      // remove ',00' and '.00'
      value = value.replace(',00', '');
      value = value.replace('.00', '');

      // remove anything but numbers
      value = value.replace(/[^0-9]/g, '');

      // remove leading zeros
      value = value.replace(/^0+/, '');

      // add dots for every 1000
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      el.value = value;
    }
  };

  document.body.addEventListener('focusout', focusoutHandler);

  // mousedown listener --------------------------------
  document.body.addEventListener('mousedown', event => {
    // el = elFromEvent('.swiper-wrapper', event);
    // if (el && 'aim' in Once) {
    //   Once.aim.classList.add('animate-it-mouse--dragging-hor');
    // }
  }, true);

  // mouseup listener --------------------------------
  document.body.addEventListener('mouseup', event => {
    // if ('aim' in Once) {
    //   Once.aim.classList.remove('animate-it-mouse--dragging-hor');
    // }
  }, true);
};

// functions -------------------------------------------------------------------
export const elFromEvent = (selector, event) => {
  const {target} = event;
  const ancestor = target.closest(selector);
  return ancestor || target.matches(selector);
};

export const setBankRates = () => {
  // console.log('setBankRates');

  const typeEl = document.querySelector('.js-label-type select');
  const energyEl = document.querySelector('.js-label-energy select');

  // console.log({typeEl, energyEl});
  if (!typeEl || !energyEl) return;

  const type = typeEl.value;
  const energy = energyEl.value;
  // console.log({type, energy});

  const selector = `.interests--banks[data-type="${type}"][data-energy="${energy}"]`;

  const interestsEl = document.querySelector(selector);
  // console.log({ selector, interestsEl });
  if (!interestsEl) return;

  const switcherEl = interestsEl.closest('.switcher');
  if (!switcherEl) return;

  switcherEl.classList.add('switcher--faded-out');

  setTimeout(() => {
    switcherEl.classList.remove('switcher--faded-out');

    // hide other items for this bank
    const interestsEls = document.querySelectorAll('.interests--banks');
    interestsEls.forEach(interestsEl => {
      interestsEl.classList.add('hidden');
    });

    // show and fade current morgage type
    interestsEl.classList.remove('hidden');
    Once.fader.fadeInHolderAgain(selector);
  }, Vars.transitionDefault);
};


// document ready listener
document.addEventListener('DOMContentLoaded', listeners);
