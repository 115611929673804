// everything related to resizing, viewport, window, document, elements

// imports --------------------------------------------------------------------
import * as Vars from './0-vars.js';
import * as Once from './2-once.js';
import * as Scroll from './5-scroll.js';

// exported variables ---------------------------------------------------------
export let windowWidth = 0;
export let windowInnerWidth = 0;
export let windowHeight = 0;
export let windowInnerHeight = 0;
export let viewportHeight = 0;
export let fullTouch = 0;
export let navbarHeight = 0;
export let navbarPresent = true;
export let navbarCurrentHeight = 0;

export let documentWidth = 0;
export let documentHeight = 0;
export let maxBodyScrollTop = 0;
export let maxBodyScrollLeft = 0;

export const gutter = 40;

export let interestHeaderLeftWidth = 0;
export let interestHeaderRightDiff = 0;
export let interestHeaderHeight = 0;
export let navHeight = 0;

export let interestHeight = 0;
export let interestGap = 0;

export let articleImgHeight = 0;

export let blockResizeObserver = false; // set to true during animations to not call setResize

export function setBlockResizeObserver(value) {
  blockResizeObserver = value;
}

// local variables -------------------------------------------------------------
const rootStyle = document.documentElement.style;
const resizeHolders = ['.main', '.popover']; // comment to turn off resize observer

let prevInnerWidth = 0;
let prevObserverWidth = 0;
let prevObserverHeight = 0;

let footerTop = 0;
// let navHeight;
// let footerHeight;

// resize function -------------------------------------------------------------
export const setResize = (force = false) => {
  if (typeof force === 'object') force = false;
  // console.log('setResize', force);

  // do resizing actions
  windowWidth = document.documentElement.clientWidth; // width without scrollbar
  windowInnerWidth = window.innerWidth; // width with scrollbar
  windowHeight = document.documentElement.clientHeight; // height minus navbar on touch
  windowInnerHeight = window.innerHeight; // height minus current navbar on touch
  viewportHeight = window.visualViewport.height; // height minus current navbar and keyboard on touch
  const ftEl = document.querySelector('.full-touch');
  if (ftEl) {
    fullTouch = ftEl.offsetHeight; // height including navbar on touch
    navbarHeight = ftEl.offsetHeight - windowHeight;
  } 
  navbarPresent = windowInnerHeight === windowHeight ? 1 : 0;
  navbarCurrentHeight = navbarPresent * navbarHeight;

  rootStyle.setProperty('--window-width', windowWidth + 'px');
  rootStyle.setProperty('--window-height', windowHeight + 'px');
  rootStyle.setProperty('--inner-height', windowInnerHeight + 'px'); // sometimes wrong on android
  rootStyle.setProperty('--viewport-height', viewportHeight + 'px');
  rootStyle.setProperty('--navbar-height', navbarHeight + 'px');
  rootStyle.setProperty('--current-navbar-height', navbarCurrentHeight + 'px');

  // on touch, if the body is fixed, menu is always present
  // so adding height: 100% is the way to add a correct size popover
  // or by setting height to windowHeight with js

  Scroll.checkBlockScroll(); // before resizing

  // when resizing width or height ---------------------------------------------
  // resize things

  // Once.sunWiggler.distance = windowWidth / 2;

  const interestHeaderLeftEl = document.querySelector('.js-interest-header-left');
  if (interestHeaderLeftEl) {
    interestHeaderLeftWidth = interestHeaderLeftEl.offsetWidth;
    rootStyle.setProperty('--interest-header-left-width', interestHeaderLeftWidth + 'px');
  } 

  let interesHeaderRightEl =  document.querySelector('.interest-header__block--energy');
  if (!interesHeaderRightEl) interesHeaderRightEl = document.querySelector('.interest-header__block--label');
  let rectRight;
  const interestRightEls = document.querySelectorAll('.interests__item-calc');
  interestRightEls.forEach(interestRightEl => {
    if (interestRightEl.offsetWidth === 0) return;
    rectRight = interestRightEl.getBoundingClientRect();
  })
  if (interesHeaderRightEl && rectRight) {
    const rect = interesHeaderRightEl.getBoundingClientRect();
    interestHeaderRightDiff = rectRight.width - rect.width;
    // console.log(interestHeaderRightDiff);
    rootStyle.setProperty('--interest-header-right-diff', interestHeaderRightDiff + 'px');
  }

  const interestHeaderEl = document.querySelector('.interest-header');
  if (interestHeaderEl) {
    interestHeaderHeight = interestHeaderEl.offsetHeight;
    rootStyle.setProperty('--interest-header-height', interestHeaderHeight + 'px');
  }

  const navEl = document.querySelector('.nav');
  if (navEl) {
    navHeight = navEl.offsetHeight;
    rootStyle.setProperty('--nav-height', navHeight + 'px');
  }

  const interestEl = document.querySelector('.interests__item');
  if (interestEl) {
    interestHeight = interestEl.offsetHeight;
    rootStyle.setProperty('--interest-height', interestHeight + 'px');
  }

  const gapEl = document.querySelector('.interests__gap');
  if (gapEl) {
    interestGap = gapEl.offsetHeight;
    rootStyle.setProperty('--interest-gap', interestGap + 'px');
  }

  const articleImgEl = document.querySelector('#img-article-start');
  if (articleImgEl) {
    articleImgHeight = articleImgEl.offsetHeight;
    rootStyle.setProperty('--article-img-height', articleImgHeight + 'px');
  }

  const togglerEl = document.querySelector('.toggler--2');
  if (togglerEl) {
    const togglerItemEl = togglerEl.querySelector('.toggler__item');
    const togglerItemWidth = togglerItemEl.offsetWidth;
    togglerEl.style.setProperty('--toggler-item-width', togglerItemWidth + 'px');
  }

  const infoEls = document.querySelectorAll('.info');
  infoEls.forEach(infoEl => {
    const infoContentEl = infoEl.querySelector('.info__content');
    if (!infoContentEl) return;

    let rect = infoEl.getBoundingClientRect();
    let toWindowSide = 0;
    let contentRect = infoContentEl.getBoundingClientRect();
    if (rect.left > windowWidth / 2) {
      infoContentEl.classList.add('info__content--left');
      toWindowSide = contentRect.right;
    } else {
      infoContentEl.classList.remove('info__content--left');
      toWindowSide = windowWidth - contentRect.left;
    }

    infoContentEl.style.setProperty('--to-window-side', toWindowSide + 'px');
  });

  // for animating max height
  const selector = '.highlight__hider, .term__content, .interests-holder';
  const els = document.querySelectorAll(selector);
  els.forEach(el => {
    // clamp breaks scrollHeight value, so remove it temporarily
    let noClamp = false;
    if (el.classList.contains('term__content')) {
      if (el.classList.contains('term__content--no-clamp')) {
        noClamp = true;
      } else {
        el.classList.add('term__content--no-clamp');
      }
    }

    const scrollHeight = el.scrollHeight;
    if (scrollHeight === 0) return;
    el.style.setProperty('--scroll-height', scrollHeight + 'px');

    if (el.classList.contains('term__content') && !noClamp) {
      el.classList.remove('term__content--no-clamp');
    }
  })

  // check if resize width or forced
  if (windowInnerWidth === prevInnerWidth && force === false) {
    saveOffsets();
    Scroll.setScroll();
    return;
  }

  prevInnerWidth = windowInnerWidth;

  // from here is skipped when only resizing height ----------------------------
  // code...

  // last
  saveOffsets();
  Scroll.setScroll();
};

// functions -------------------------------------------------------------------
const saveOffsets = () => {
  // console.log('saveOffsets', typeof Once.fixer);

  if ('fixer' in Once && typeof Once.fixer.bodyFixed !== 'undefined') {
    if (Once.fixer.bodyFixed !== true) {
      doSaveOffsets();
    }
  } else {
    doSaveOffsets();
  }
};

const doSaveOffsets = () => {
  // always call this through saveOffsets

  // save offsets here
  documentHeight = document.documentElement.scrollHeight; // entire document height
  maxBodyScrollTop = documentHeight - windowInnerHeight;
  rootStyle.setProperty('--document-height', documentHeight + 'px');

  documentWidth = document.documentElement.scrollWidth; // entire document width
  maxBodyScrollLeft = documentWidth - windowWidth;

  const navEl = document.querySelector('.nav');
  if (navEl) navHeight = navEl.offsetHeight;

  // repeater speed
  const repeaterEls = document.querySelectorAll('.ticker__repeater');
  repeaterEls.forEach(repeaterEl => {
    const rect = repeaterEl.getBoundingClientRect();
    const {width} = rect;
    // set css animation duration based on width
    const duration = width / 100;
    repeaterEl.style.setProperty('--duration', duration + 's');
  });

  // const footerEl = document.querySelector('.footer');
  // if (footerEl) {
  //   rect = footerEl.getBoundingClientRect();
  //   footerTop = rect.top - windowHeight + Scroll.scrollTop;
  // }
};

// helper functions ------------------------------------------------------------
// check for resizing of holders
const observerResize = () => {
  // console.log('observerResize', blockResizeObserver, windowWidth, prevObserverWidth, windowHeight, prevObserverHeight);

  // prevent double resize when resizing window
  if (
    (windowWidth !== prevObserverWidth || windowHeight !== prevObserverHeight)
    && prevObserverWidth !== 0
  ) {
    prevObserverWidth = windowWidth;
    prevObserverHeight = windowHeight;
    return;
  }

  prevObserverWidth = windowWidth;
  prevObserverHeight = windowHeight;

  // not animating? do resize
  if (!blockResizeObserver) setResize(true);
};

// resize listeners ------------------------------------------------------------
// visual viewport resize listener
window.visualViewport.addEventListener('resize', setResize);

Vars.mqPortrait.addEventListener('change', () => {
  setTimeout(setResize, 100);
});

// resize observer
if (typeof resizeHolders !== 'undefined') {
  for (let i = 0; i < resizeHolders.length; i++) {
    // add an observer for each holder that resizes
    const el = document.querySelector(resizeHolders[i]);
    if (el !== null) new ResizeObserver(observerResize).observe(el);
  }
}
