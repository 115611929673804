// Fader V6.33
// used for fading in items, if needed after being loaded, based on scroll
// also copy _fader.scss

// init: fader = new Fader();

// usage: add fader class to items that should fade
// set fader and fader--loader class on loading element or a parent element
// add fader--lazy on fader--loader for lazyloading
// loader can de used for a single image, video or background-image
// use data-src and data-srcset instead of src(set)
// can use data-src(set)-mobile
// can use data-src-fullscreen with fader--fullscreen
// add fader--repeat to fade every time it crosses a fold
// add fader--top to fade at the top fold too

// required properties:
// none

// possible properties:
// fadeDur = 1200: set this to fader.scss fade speed, in ms
// stagger = 100: in ms
// maxFades = 10: max number of staggering items
// preFadeResizeDur = 100: for resizing used after knowing image size, in ms,
// set to image size transition duration
// afterLoadFunction = '': because now stuff has loaded and size is known call this function
// fadeScrollersSelector = '': what selectors uses fade except the body
// classesToRemove = []: what classes should be removed from the element that you added
// to the css, for instance ['fader--grow']
// noFadeOnInit = true: set to true to not fade immediately when creating fader
// showMobileVideoSrcOnMobileHor = false: if true, displays the mobile video source
// if you are looking on a horizontal mobile device. Probably set to false if using
// video that covers the viewport. Set to true if just using a smaller filesize
// mobileWidth = 599: from where is it mobile
// blockResizeObserver = false: block the observer from calling setFaders
// lazyFoldDiff = 1000: load images before they are visible for lazyFoldDiff pixels
// fadeBelowFold = false: fade elements that are below the fold
// skipOnLoadInView = false: skip fading if element is already in view because of LCP/FCP

// methods:
// fader.setFaders() when new content is added to the page
// fader.fadeBlocks() if scroll doesn't fire
// fader.fadeInHolderAgain(holderSelector) to redo fade
// fader.fadeElementsAgain(selector) to redo fade

// use .fader--loaded to remove temporary heights for images, for example:
// min-height: 0;
// transition: .2s min-height;
// &:not(.fader--loaded) {
//   min-height: 10rem;
// }

// Changelog
// 4.0 can switch between regular src and mobile src continuously
// 4.1 custom mobileWidth
// 4.1.1 fix for offscreen safari video
// 4.1.2 fix for unmuted videos not playing
// 4.2 switch media queries more nicely and only where needed
// 4.3 css support for clipping
// 4.4 fixes for changing variables
// 4.5 added resize observer
// 4.5.1 eslint
// 4.5.2 add blockResizeObserver fix, cleanup
// 4.5.3 fix resize observer
// 4.6 add no stagger option
// 4.6.2 fix for safari video, cleanup
// 4.6.2 new cover class
// 4.6.3 fix for cover video
// 4.7 new --no-fade, --still, --clip fixes, separate animation duration
// 4.7.1 fix bug with no fade for loaded elements
// 5.0 add lazyload option
// 6.0 no jquery
// 6.1 cleanup
// 6.1.1 use correct window sizes
// 6.2 two seperate fade again functions
// 6.2.1 fix for safari
// 6.2.2 fix wrong offset, add custom lazyFoldDiff
// 6.2.3 cleanup and bugfixes
// 6.2.4 cleanup of getAttribute code
// 6.3 add audio support
// 6.4 fix non-autoplay video autoplaying on ios
// 6.5 add fade below fold option (to hide fade / flash)
// 6.6 fix for faders in scrolling elements, cleanup
// 6.6.1 don't fade again if still fading
// 6.7 add fader repeat option
// 6.8 add top option to fade again
// 6.9 add option for different transition from above fold
// 6.9.1 fix for empty fadeScrollersSelector
// 6.10 add individual fade below option using fader--below-fold
// 6.11 don't fade invisible elements
// 6.12 add data-fader-delay option
// 6.13 new transform options
// 6.14 firefox animation improvements
// 6.15 fix stagger timing
// 6.16 add fader--fullscreen option, which uses data-src-fullscreen, fixes to resizing
// 6.16.1 only add load listeners to element with new src, call less fadeblocks after load
// 6.16.2 fix for loading fixed elements
// 6.17 fix for calling function after load
// 6.18 make resetLoaderElement public
// 6.19 check if src is set before loading with it
// 6.20 fix fadeBelowFold with fader--loader
// 6.21 fix flicker from fader--loader
// 6.22 add retina firefox translate support
// 6.23 don't reload media when repeating fade
// 6.24 fix for weird html characters in src
// 6.25 fix for no js browsers
// 6.26 take transform into account for position
// 6.27 trim sources and fix weird characters
// 6.28 don't use LF scss/css vars
// 6.29 fix for no fade on elements that are in view on load
// 6.30 fixes for fcp/lcp
// 6.31 fix 'fade again' for in view on load elements
// 6.32 fix weird characters some more
// 6.33 fixes for skip on load in view

// Backlog
// use data attributes instead of dataset

export default class Fader {
  constructor({
    fadeDur = 1200,
    stagger = 80,
    maxFades = 10,
    preFadeResizeDur = 100,
    afterLoadFunction = '',
    fadeScrollersSelector = '',
    classesToRemove = [],
    fadeOnInit = true,
    showMobileVideoSrcOnMobileHor = false,
    mobileWidth = 599,
    blockResizeObserver = false,
    lazyFoldDiff = 1000,
    fadeBelowFold = false,
    skipOnLoadInView = false,
  } = '') {
    // console.log('Fader');

    // user variables ------------------------------------------------------------
    // can be changed based on a media query for instance and read
    this.fadeDur = fadeDur;
    this.stagger = stagger;
    this.maxFades = maxFades;
    this.preFadeResizeDur = preFadeResizeDur;
    this.afterLoadFunction = afterLoadFunction;
    this.blockResizeObserver = blockResizeObserver;
    this.lazyFoldDiff = lazyFoldDiff;
    this.fadeBelowFold = fadeBelowFold;
    this.skipOnLoadInView = skipOnLoadInView;

    // scope variables -----------------------------------------------------------
    let fadersAnimating = 0;
    const mqMobile = window.matchMedia('(max-width: ' + mobileWidth + 'px)');
    const mqMobileHor = window.matchMedia('(max-height: 450px)');
    const isTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

    const ua = navigator.userAgent;
    if (ua.toLowerCase().indexOf('firefox') > -1) document.body.classList.add('body--firefox');

    const mq = window.matchMedia('only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)');
    if ((mq && mq.matches) || window.devicePixelRatio > 1) document.body.classList.add('body--retina');

    let windowWidth = 0;
    let windowHeight = 0;
    let prevObserverWidth = 0;
    let prevObserverHeight = 0;
    let fold = window.innerHeight; // must use innerHeight because of ios: is actual height of screen
    let lazyFold = fold + this.lazyFoldDiff; // load images before they are visible

    let fadeBlocksTimeout;
    const fastLoadDuration = 100;

    // functions -----------------------------------------------------------------
    const getLoadElement = element => {
      // console.log('getLoadElement', {element});
      let loadableElement;

      if (
        element.tagName === 'IMG'
        || element.tagName === 'VIDEO'
        || element.tagName === 'AUDIO'
        || getComputedStyle(element).backgroundImage !== 'none'
      ) {
        loadableElement = element;
      } else {
        const selector = 'img[data-src], video[data-src], audio[data-src], *[style*="background-image:"]';
        loadableElement = element.querySelector(selector);
      }

      return loadableElement;
    };

    // when new fader content is added, call this
    this.setFaders = () => {
      // console.log('setFaders');
      windowWidth = document.documentElement.clientWidth;
      windowHeight = document.documentElement.clientHeight;
      fold = window.innerHeight;
      lazyFold = fold + this.lazyFoldDiff;

      // calculate --vert
      faderEl.classList.remove('fader');
      const startTop = faderEl.getBoundingClientRect().top;
      faderEl.classList.add('fader');
      const endTop = faderEl.getBoundingClientRect().top;
      vert = startTop - endTop;

      const fadeSelectorHandler = event => {
        this.fadeBlocks(event.target);
      };

      if (fadeScrollersSelector !== '') {
        const fadeScrollers = document.querySelectorAll(fadeScrollersSelector);
        fadeScrollers.forEach(element => {
          element.removeEventListener('scroll', fadeSelectorHandler);
          element.addEventListener('scroll', fadeSelectorHandler);
        });
      }

      const faderElements = document.querySelectorAll('.fader');
      faderElements.forEach(element => {
        let hasFastLoad = false;
        if (element.classList.contains('fader--fast-load')) {
          hasFastLoad = true;
          // don't add fast-load class to data-class
          // because its should only be used on page load
          element.classList.remove('fader--fast-load');
        } 

        if (!element.getAttribute('data-class')) {
          element.setAttribute('data-class', element.getAttribute('class'));
        }

        if (hasFastLoad) element.classList.add('fader--fast-load');

        element.classList.add('fader2');

        // if above fold, add top transform
        const rect = element.getBoundingClientRect();
        if (element.classList.contains('fader--top') && rect.bottom < 0) element.classList.add('fader--top-start');
      });

      const loaderElements = document.querySelectorAll('.fader--loaded:not(.fader--lazy), .fader--loader:not(.fader--lazy)');
      loaderElements.forEach(element => {
        // console.log('loadElement not lazy', {element});
        loadElement(element);
      });

      fadeTimeout();
    };

    this.resetLoaderElement = element => {
      // console.log('resetLoaderElement', {element});
      tryRemoveFaderProperties(element);

      element.classList.remove('fader--loaded');
      element.classList.remove('fader--loading');
      element.classList.add('fader');
      element.classList.add('fader--loader');
      element.dataset.fadeFromScroll = '';
      element.dataset.fadeFromLoad = '';
      element.dataset.faded = '';

      const loadEl = getLoadElement(element);
      if (loadEl.tagName === 'IMG' || loadEl.tagName === 'VIDEO') {
        loadEl.setAttribute('src', '');
        loadEl.setAttribute('srcset', '');
      }
    };

    const loadElement = element => {
      // console.log('loadElement', {element});
      let loadEl = getLoadElement(element);

      if (!loadEl) {
        // console.log('nothing to load, remove loader, just fade');
        element.classList.remove('fader--loader');
        return;
      }

      // if invisible and not audio, don't fade
      const position = window.getComputedStyle(element).getPropertyValue('position');
      if (element.offsetParent === null && position !== 'fixed' && element.tagName !== 'AUDIO' && loadEl.tagName !== 'AUDIO') return;

      // console.log('loadElement 2', {element}, {loadEl}); 

      const src = loadEl.getAttribute('data-src');
      const srcMobile = loadEl.getAttribute('data-src-mobile');

      if (loadEl.tagName === 'IMG') {
        // images ----------------------------
        const srcset = loadEl.getAttribute('data-srcset');
        const srcsetMobile = loadEl.getAttribute('data-srcset-mobile');

        if (srcMobile && mqMobile.matches) { // mobile
          addImgLoadCheck(element, loadEl, srcMobile, srcsetMobile);
        } else if (src) {
          // not mobile
          addImgLoadCheck(element, loadEl, src, srcset);
        }
      } else if (loadEl.tagName === 'VIDEO' || loadEl.tagName === 'AUDIO') {
        // video and audio ----------------------------
        const srcFullscreen = loadEl.getAttribute('data-src-fullscreen');
        const isFullscreen = element.classList.contains('fader--fullscreen');

        if (srcMobile && (
          (mqMobile.matches && !mqMobileHor.matches)
            || (mqMobileHor.matches && showMobileVideoSrcOnMobileHor)
        )
        ) {
          // mobile
          addVideoAudioLoadCheck(element, loadEl, srcMobile);
        } else if (srcFullscreen && isFullscreen) {
          // fullscreen
          addVideoAudioLoadCheck(element, loadEl, srcFullscreen);
        } else if (src) {
          // not mobile or fullscreen
          addVideoAudioLoadCheck(element, loadEl, src);
        }
      } else {
        // background images ----------------------------

        const bgImg = getComputedStyle(element).backgroundImage;
        const imgUrl = bgImg.slice(4, -1).replace(/"/g, '');
        const img = new Image();

        img.removeEventListener('load', () => {});
        img.addEventListener('load', () => {
          img.remove();
          element.style.backgroundImage = `url(${imgUrl}) !important`;

          fadedAfterLoad(element);
        });

        img.src = imgUrl;
      }
    };

    const addImgLoadCheck = (element, loadEl, src, srcset) => {
      // console.log('addImgLoadCheck', {element}, {loadEl});

      // remove leading and ending spaces, and decode the url
      src = src.trim(); 
      src = decodeURIComponent(src);

      if (src == decodeURIComponent(loadEl.src)) return;

      this.resetLoaderElement(element);
      loadEl.removeEventListener('load', () => {});
      loadEl.addEventListener('load', () => {
        fadedAfterLoad(element);
      });

      element.classList.add('fader--loading');
      loadEl.src = src;
      if (srcset) {
        // remove leading and ending spaces, and decode the url
        srcset = srcset.trim();
        srcset = decodeURIComponent(srcset);
        loadEl.srcset = srcset;
      } 
    };

    const addVideoAudioLoadCheck = (element, loadEl, src) => {
      // console.log('addVideoAudioLoadCheck', {element}, {loadEl});

      // remove leading and ending spaces, and decode the url
      src = src.trim(); 
      src = decodeURIComponent(src);

      if (src == loadEl.src) return;

      // all video's play to load
      // couldn't do from loadeddata event, because play sometimes starts after that and pause wouldn't work
      loadEl.removeEventListener('play', () => {});
      loadEl.addEventListener('play', () => {
        // console.log('playing', event, loadEl.dataset.mayplay);
        if (isTouch && loadEl.dataset.autoplay === 'false' && loadEl.dataset.mayplay !== 'true') {
          // in new frame because pause would sometimes not work
          requestAnimationFrame(() => {
            loadEl.autoplay = false;

            loadEl.pause();
            loadEl.currentTime = 0;

            // only do this once, from initial play event used to load video
            loadEl.dataset.mayplay = 'true';
          });
        }

        if (loadEl.getAttribute('data-muted') === 'false') loadEl.muted = false;
      });

      loadEl.removeEventListener('loadeddata', () => {});
      loadEl.addEventListener('loadeddata', () => {
        fadedAfterLoad(element);
      });

      const autoplay = loadEl.getAttribute('data-autoplay');

      if (isTouch && !autoplay) {
        if (loadEl.muted === false) {
          loadEl.setAttribute('data-muted', 'false');
          loadEl.muted = true;
        } else {
          loadEl.setAttribute('data-muted', 'true');
        }

        if (loadEl.autoplay === false) {
          loadEl.setAttribute('data-autoplay', 'false');
          loadEl.autoplay = true;
        } else {
          loadEl.setAttribute('data-autoplay', 'true');
        }
      }

      this.resetLoaderElement(element);
      loadEl.src = src;
    };

    const fadedAfterLoad = element => {
      // console.log('fadedAfterLoad', {element});
      // you need all 3 to stop calling loadElement
      element.classList.remove('fader--loading');
      element.classList.remove('fader--loader');
      element.classList.remove('fader--lazy');

      element.classList.add('fader--loaded');

      // remember it's loaded
      const dataClass = element.getAttribute('data-class');
      if (dataClass.indexOf('fader--loaded') === -1) {
        element.setAttribute('data-class', dataClass + ' fader--loaded');
      }

      fadeTimeout();

      const duration = element.classList.contains('fader--fast-load') ? fastLoadDuration : this.fadeDur;

      setTimeout(() => {
        element.dataset.fadeFromLoad = true;
        tryRemoveFaderProperties(element);
      }, parseInt(element.getAttribute('data-delay'), 10) + duration + this.preFadeResizeDur);
    };

    const fadeTimeout = () => {
      // console.log('fadeTimeout');
      // resize after certain amount of time, when elements have resized
      // by default this is 100ms
      clearTimeout(fadeBlocksTimeout);
      fadeBlocksTimeout = setTimeout(() => {
        // console.log('fadeTimeout fadeBlocks');
        this.fadeBlocks(document.body);

        if (fadeScrollersSelector !== '') {
          const fadeScrollers = document.querySelectorAll(fadeScrollersSelector);
          fadeScrollers.forEach(element => {
            this.fadeBlocks(element);
          });
        }

        // call after load function
        if (this.afterLoadFunction) this.afterLoadFunction();
      }, preFadeResizeDur);
    };

    const scrollHandler = event => {
      this.fadeBlocks(event.target);
    };

    this.fadeBlocks = scrollEl => {
      // console.log('fadeBlocks', {scrollEl, vert});

      // fade in the blocks when scrolling
      const faderElements = scrollEl.querySelectorAll('.fader2');
      faderElements.forEach(element => {
        // console.log(element.classList[0]);
        if (!element.classList.contains('fader') && !element.classList.contains('fader--repeat')) return;

        // if invisible, don't fade
        const position = window.getComputedStyle(element).getPropertyValue('position');
        if (element.offsetParent === null && position !== 'fixed') return;

        const rect = element.getBoundingClientRect();
        let top = rect.top;
        let bottom = rect.bottom;

        if (element.classList.contains('fader')) {
          if (element.classList.contains('fader--up')) {
            // is transformed 100%, so add element height
            top -= rect.height;
            bottom += rect.height;
          } else if (element.classList.contains('fader--left') || element.classList.contains('fader--right')) {
            // is transformed sideways, so add no vert
          } else {
            // is transformed, so add vert
            top += vert; 
            bottom -= vert;
          }
        } 

        // lazy load images before they are visible
        if (element.classList.contains('fader--lazy') && (top < lazyFold || this.fadeBelowFold)) {
          // console.log('lazy load', {element});
          loadElement(element);
        } 

        // console.log({element}, {top}, {bottom}, {fold}, element.dataset.faded);

        if ((top < fold && !element.classList.contains('fader--top')) // only from bottom
        || (top < fold && bottom > 0 && element.classList.contains('fader--top')) // from top and bottom
        || this.fadeBelowFold
        || element.classList.contains('fader--below-fold')) {
          if (element.dataset.faded) return;

          // console.log('fade from scroll: ', {element, top});
          // only do this once
          element.dataset.faded = 'true';

          // console.log(element, fadersAnimating);
          if (element.classList.contains('fader--no-fade')) {
            // non-fading element
            element.classList.add('fader3'); // visible
            element.dataset.fadeFromScroll = 'true';
            tryRemoveFaderProperties(element);
          } else {
            // console.log('fade element: ', element);
            // fading element
            // add a delayed animation for the animating blocks
            let extraDelay;
            let delay;

            if (element.classList.contains('fader--fast-load')) {
              extraDelay = 0;
              delay = 0;
            } else{
              extraDelay = element.dataset.faderDelay ? parseInt(element.dataset.faderDelay, 10) : 0;
              delay = (this.stagger * fadersAnimating) + this.preFadeResizeDur + extraDelay;
            }

            // console.log(this.fadeDur, { delay });
            element.style.transitionDelay = delay + 'ms';
            element.setAttribute('data-delay', delay); // remember delay for loading media
            element.classList.add('fader3'); // fade in

            // remember total fading blocks, to get them to not animate at the same time
            if (!element.classList.contains('fader--no-stagger') 
            &&  !element.classList.contains('fader--fast-load')) {
              fadersAnimating++;
            } 

            // maxFades so you don't have to wait too long when scrolling down fast
            if (fadersAnimating > this.maxFades) fadersAnimating = this.maxFades;

            // remove stagger
            setTimeout(() => {
              // console.log('start ani');
              fadersAnimating--;
              if (fadersAnimating < 0) fadersAnimating = 0;
            }, delay);


            const duration = element.classList.contains('fader--fast-load') ? fastLoadDuration : this.fadeDur;

            // console.log('fade from scroll', element.classList[0], {duration}, {delay}, {fadersAnimating});

            // after fading in
            setTimeout(() => {
              element.dataset.fadeFromScroll = 'true';
              tryRemoveFaderProperties(element);
            }, duration + delay);
          }
        } else if (element.classList.contains('fader--repeat') && element.dataset.fadeFromScroll) {
          // this is a faded repeat element below bottom, or above top fold
          fadeAgain(element);
          this.setFaders();
        }
      });
    };

    this.fadeInHolderAgain = holderSelector => {
      // console.log('fadeInHolderAgain', holderSelector);

      // get all faders from holder
      const holder = document.querySelector(holderSelector);
      const elements = holder.querySelectorAll('.fader2');

      elements.forEach(fadeAgain);
      this.setFaders();
    };

    this.fadeElementsAgain = selector => {
      // console.log('fadeElementsAgain', selector);
      const elements = document.querySelectorAll(selector);
      elements.forEach(fadeAgain);
      this.setFaders();
    };

    const fadeAgain = element => {
      // console.log('fadeAgain', {element});
      if (element.classList.contains('fader3')) return; // still fading, don't reset

      tryRemoveFaderProperties(element);
      element.dataset.faded = '';
      element.dataset.fadeFromScroll = '';
      element.setAttribute('class', element.getAttribute('data-class')); // reset class
    };

    const tryRemoveFaderProperties = element => {
      // console.log('tryRemoveFaderProperties', element.dataset.fadeFromLoad, element.dataset.fadeFromScroll);
      // if faded from scroll and loaded
      // if faded from scroll and not loading
      // if faded from load and scrolling isn't relevant
      if (
        (element.dataset.fadeFromScroll && element.dataset.fadeFromLoad)
        || (element.dataset.fadeFromScroll
          && !element.classList.contains('fader--loader')
          && !element.classList.contains('fader--loading')
          && !element.classList.contains('fader--loaded'))
        || (element.dataset.fadeFromLoad && element.classList.contains('fader--no-scroll'))
      ) {
        // console.log('remove properties', element);

        const defaultClassesToRemove = ['fader', 'fader3', 'fader--no-scroll', 'fader--still', 'fader--no-fade', 'fader--clip', 'fader--no-stagger', 'fader--left', 'fader--right', 'fader--bl', 'fader--br', 'fader--shrink', 'fader--lazy', 'fader--top-start', 'fader--up', 'fader--fast-load'];

        for (let i = 0; i < defaultClassesToRemove.length; i++) {
          element.classList.remove(defaultClassesToRemove[i]);
        }

        for (let i = 0; i < classesToRemove.length; i++) {
          element.classList.remove(classesToRemove[i]);
        }

        element.removeAttribute('data-delay');

        // leave fader--loaded and fader2 class because they're used elsewhere

        element.style.transitionDelay = '';
      }
    };

    // check for resizing of holders --------------------------------
    const observerResize = () => {
      // console.log('observerResize', this.blockResizeObserver, {windowWidth}, {prevObserverWidth}, {windowHeight}, {prevObserverHeight});

      // observed a resize but browser did not resize, this means content changed, so call setResize
      if (windowWidth == prevObserverWidth && windowHeight == prevObserverHeight && prevObserverWidth != 0 && prevObserverHeight != 0) {
        if (!this.blockResizeObserver) this.setFaders();
      }

      prevObserverWidth = windowWidth;
      prevObserverHeight = windowHeight;
    };

    const observerHandler = () => {
      // after possible site specific resize calculations
      if (!this.blockResizeObserver) setTimeout(observerResize, 100);
    };

    window.addEventListener('load', fadeTimeout);
    document.addEventListener('scroll', scrollHandler);

    window.addEventListener('resize', () => {
      // after possible site specific resize calculations
      setTimeout(this.setFaders, 100);
    });

    // add an observer, so the resizer is called when body size changes
    const el = document.querySelector('body');
    new ResizeObserver(observerHandler).observe(el);

    // create fader el to calculate vertical offset --------------------------------
    let vert = 0;
    const faderEl = document.createElement('div');
    document.body.insertBefore(faderEl, document.body.firstChild);

    // remove fade from elements in view on load --------------------------------
    if (skipOnLoadInView) {
      const faderEls = document.querySelectorAll('.fader');
      // console.log('skipOnLoadInView', {faderEls});

      faderEls.forEach(faderEl => {
        const rect = faderEl.getBoundingClientRect();
        if (rect.top < fold) {
          // faderEl is in view

          if (faderEl.classList.contains('fader--loader')) {
            // faderEl needs loading, so still do fade but faster
            faderEl.classList.add('fader--fast-load');
            // console.log(faderEl.classList[0], 'fast fade');
          } else {
            // faderEl doesn't need loading, so skip fade

            if (!faderEl.getAttribute('data-class')) {
              faderEl.setAttribute('data-class', faderEl.getAttribute('class'));
            }

            // console.log(faderEl.classList, 'skip fade');

            faderEl.classList.add('fader2');
            faderEl.dataset.fadeFromScroll = 'true';
            tryRemoveFaderProperties(faderEl);
          }
        }
      });
    }

    // start using fader elements
    // console.log({fadeOnInit});
    if (fadeOnInit) this.setFaders();
  }
}
