// Fold Out V6.1
// perfect fold out and close animation, removes overflow hidden after animation
// also copy fold-out.scss

// adds --active modifier to button
// requires data-fold-out on button and data-nr on fold-out, both are integers
// so it knows what button opens what foldout

// init: foldOut = new FoldOut();
// usage: init fold out as above with required properties buttonClass and holderClass
// do this only once per button class
// close all foldouts using foldOut.closeFoldOuts()

// required properties:
// buttonClass: the class for the clickable buttons
// holderClass: the class for the holder of the fold outs

// possible properties:
// transitionDuration = 400: in ms. Should match transition duration of fold-out.scss
// closeOthers = true: should other fold outs in the same holder be closed when opening one
// closeAllWithSameHolderClass = false: does what it says, only works if closeOthers is true
// scrollAfterSwitch = false, editable: should the page scroll to the clicked button, only works if closeOthers is true and the clicked button is below the previously clicked button
// scrollAfterSwitchOffset: in px, editable, negative. To where relative to the btn should the page scroll during fold out (normally so that the foldout btn is fully visible)
// callback = '': called after opening and closing a fold out
// callbackDuringAnimation = false: should the function be called during the entire transition?
// hasVideos = false: can it have <div class="video"> elements
// hasVideoEmbeds = false: can is have <div class="video-embed"> elements

// methods:
// foldout.closeFoldOuts(): no properties
// foldout.openFoldout(button): required button element

// changelog
// 4.0: perfect opening and closing
// 4.1 trigger a resize / callback, after / during opening or closing
// 4.2 fixed bug for clicking button not in holder (which should do nothing)
// 4.2.1 use animateScrollTop instead of animateScroll
// 4.3 fixed not using changed variables
// 4.3.1 eslint
// 4.4 remove jquery animate, use requestAnimationFrame
// 4.4.1 check for video functions
// 5.0 no jquery needed
// 5.1 fix for closing foldout in other holder with same nr
// 5.2 cleanup
// 5.3 fix for clicking on element in button, better element var names
// 5.4 now opens all corresponding foldouts if there are multiple, cleanup
// 5.5 make it possible to animate multiple foldouts at the same time
// 6.0 breaking change: foldouts have data-btn-class attribute, nicer animation
// 6.1 keyboard focus skips closed foldouts

export default class FoldOut {
  constructor({
    buttonClass,
    holderClass,
    transitionDuration = 600,
    closeOthers = true,
    closeAllWithSameHolderClass = false,
    scrollAfterSwitch = false,
    scrollAfterSwitchOffset = 0,
    callback = '',
    callbackDuringAnimation = false,
    hasVideos = false,
    hasVideoEmbeds = false,
  } = '') {
    // user variables ------------------------------------------------------------
    // can be changed based on a media query for instance and read
    this.transitionDuration = transitionDuration;
    this.closeOthers = closeOthers;
    this.closeAllWithSameHolderClass = closeAllWithSameHolderClass;
    this.scrollAfterSwitch = scrollAfterSwitch;
    this.scrollAfterSwitchOffset = scrollAfterSwitchOffset;
    this.callback = callback;
    this.callbackDuringAnimation = callbackDuringAnimation;
    this.hasVideos = hasVideos;
    this.hasVideoEmbeds = hasVideoEmbeds;

    // scope variables -----------------------------------------------------------
    // when you click the first button, remember its parent
    let buttonHolderEl;
    let holderEls;

    // functions -----------------------------------------------------------------
    // button click listener
    document.body.addEventListener('click', event => {
      let btnEl = elFromEvent('.' + buttonClass, event);
      if (btnEl) {
        const btnHolder = btnEl.closest('.' + holderClass);
        if (btnHolder) this.openFoldout(btnEl);
      }
    });

    const setMaxHeightOthers = (holderEl, nr) => {
      const foldOutEls = holderEl.querySelectorAll('.fold-out--opened[data-btn-class="' + buttonClass + '"]:not([data-nr="' + nr + '"])');
      foldOutEls.forEach(foldOutEl => {
        foldOutEl.style.maxHeight = foldOutEl.scrollHeight + 'px';
      });
    };

    const setMaxHeight = holderEl => {
      const foldOutEls = holderEl.querySelectorAll('.fold-out--opened[data-btn-class="' + buttonClass + '"]');
      foldOutEls.forEach(openFoldoutEl => {
        openFoldoutEl.style.maxHeight = openFoldoutEl.scrollHeight + 'px';
      });
    };

    const removeMaxHeightOthers = (holderEl, nr) => {
      const foldOutEls = holderEl.querySelectorAll('.fold-out--opened[data-btn-class="' + buttonClass + '"]:not([data-nr="' + nr + '"])');
      foldOutEls.forEach(foldOutEl => {
        foldOutEl.style.maxHeight = '0px';
        foldOutEl.classList.remove('fold-out--opened');
      });

      const buttonEls = holderEl.querySelectorAll('.' + buttonClass + ':not([data-fold-out="' + nr + '"])');
      buttonEls.forEach(buttonEl => {
        buttonEl.classList.remove(buttonClass + '--active');
      });
    };

    const removeMaxHeight = holderEl => {
      const foldOutEls = holderEl.querySelectorAll('.fold-out--opened[data-btn-class="' + buttonClass + '"]');
      foldOutEls.forEach(foldOutEl => {
        foldOutEl.style.maxHeight = '0px';
        foldOutEl.classList.remove('fold-out--opened');
      });

      const buttonEls = holderEl.querySelectorAll('.' + buttonClass);
      buttonEls.forEach(buttonEl => {
        buttonEl.classList.remove(buttonClass + '--active');
      });
    };

    this.openFoldout = buttonEl => {
      if (buttonEl.dataset.animating == 'true') return;
      buttonEl.dataset.animating = true;

      const nr = parseInt(buttonEl.getAttribute('data-fold-out'), 10);
      buttonHolderEl = buttonEl.closest('.' + holderClass);

      // close others ---------------------------------------
      if (this.closeOthers) {
        if (this.closeAllWithSameHolderClass) {
          holderEls = document.querySelectorAll('.' + holderClass);

          holderEls.forEach(holderEl => {
            if (holderEl == buttonHolderEl) {
              // only close others in same holderEl
              setMaxHeightOthers(holderEl, nr);
            } else {
              // other holderEl, close all
              setMaxHeight(holderEl);
            }
          });
        } else {
          setMaxHeightOthers(buttonHolderEl, nr);
        }

        requestAnimationFrame(() => {
          if (this.closeAllWithSameHolderClass) {
            holderEls.forEach(holderEl => {
              if (holderEl == buttonHolderEl) {
                // only close others in same holderEl
                removeMaxHeightOthers(holderEl, nr);
              } else {
                // other holderEl, close all
                removeMaxHeight(holderEl);
              }
            });
          } else {
            // only close others in same holderEl
            removeMaxHeightOthers(buttonHolderEl, nr);
          }

          if (this.scrollAfterSwitch) {
            const prevOpenNr = parseInt(buttonHolderEl.getAttribute('data-open-nr'), 10);

            if (prevOpenNr < nr && typeof animateScrollTop == 'function') {
              const prevOpenFoldOutEl = buttonHolderEl.querySelector('.fold-out[data-btn-class="' + buttonClass + '"][data-nr="' + prevOpenNr + '"]');
              const offsetCurrent = button.offsetTop - prevOpenFoldOutEl.offsetHeight + this.scrollAfterSwitchOffset;
              animateScrollTop(offsetCurrent);
            }

            buttonHolderEl.setAttribute('data-open-nr', nr);
          }
        });

        // after closing
        setTimeout(() => {
          if (this.hasVideoEmbeds && typeof closeVideoEmbeds == 'function') closeVideoEmbeds(otherFoldOuts);
          if (this.hasVideos && typeof hasVideos == 'function') pauseVideos(otherFoldOuts);
        }, this.transitionDuration);
      }

      // open or close this one ----------------------------
      buttonEl.classList.toggle(buttonClass + '--active');
      const foldOutEls = buttonHolderEl.querySelectorAll('.fold-out[data-btn-class="' + buttonClass + '"][data-nr="' + nr + '"]');
      foldOutEls.forEach(foldOutEl => {
        foldOutEl.style.maxHeight = foldOutEl.scrollHeight + 'px';
      });

      if (buttonEl.classList.contains(buttonClass + '--active')) {
        setTimeout(() => {
          foldOutEls.forEach(foldOutEl => {
            foldOutEl.style.maxHeight = '';
            foldOutEl.classList.add('fold-out--opened');

            // after adding --opened
            if (this.hasVideoEmbeds && typeof openVideoEmbeds == 'function') openVideoEmbeds(foldOutEl);
            if (this.hasVideos && typeof playVideos == 'function') playVideos(foldOutEl);
          });

          // because element positions changed and items became visible
          if (typeof this.callback == 'function') this.callback();

          buttonEl.dataset.animating = false;
        }, this.transitionDuration);
      } else {
        requestAnimationFrame(() => {
          foldOutEls.forEach(foldOutEl => {
            foldOutEl.style.maxHeight = '0px';
            foldOutEl.classList.remove('fold-out--opened');
          });

          setTimeout(() => {
            if (typeof this.callback == 'function') this.callback();
          }, this.transitionDuration);
        });

        // after closing
        setTimeout(() => {
          foldOutEls.forEach(foldOutEl => {
            if (this.hasVideoEmbeds && typeof closeVideoEmbeds == 'function') closeVideoEmbeds(foldOutEl);
            if (this.hasVideos && typeof pauseVideos == 'function') pauseVideos(foldOutEl);
            buttonEl.dataset.animating = false;
          });
        }, this.transitionDuration);
      }

      // may do callback and trigger resize during animation
      duringAnimation();
    };

    this.closeFoldOuts = () => {
      const animatingButtonEl = document.querySelector('.' + buttonClass + '[data-animating="true"]');
      if (animatingButtonEl) return;

      holderEls = document.querySelectorAll('.' + holderClass);
      if (holderEls && document.querySelector('.fold-out--opened[data-btn-class="' + buttonClass + '"]')) {
        const buttonEl = document.querySelector('.' + buttonClass + '--active');
        buttonEl.dataset.animating = true;

        // there are opened foldouts
        setMaxHeight(document);

        // may do callback and trigger resize during animation
        duringAnimation();

        setTimeout(() => {
          removeMaxHeight(document);

          setTimeout(() => {
            if (typeof this.callback == 'function') this.callback();
          }, this.transitionDuration);
        }, 100);

        // after closing
        setTimeout(() => {
          if (this.hasVideoEmbeds && typeof closeVideoEmbeds == 'function') closeVideoEmbeds(holder);
          if (this.hasVideos && typeof pauseVideos == 'function') pauseVideos(holder);
          buttonEl.dataset.animating = false;
        }, this.transitionDuration);
      }
    };

    const duringAnimation = () => {
      const animatingButtonEl = document.querySelector('.' + buttonClass + '[data-animating="true"]');
      if (animatingButtonEl) {
        if (this.callbackDuringAnimation) this.callback();
        requestAnimationFrame(duringAnimation);
      }
    };

    const elFromEvent = (selector, event) => {
      const {target} = event;
      const ancestor = target.closest(selector);
      return ancestor || target.matches(selector);
    };
  }
}

