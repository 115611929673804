// all your go to vars in one place

// imports --------------------------------------------------------------------

// exported variables ---------------------------------------------------------
export let blTouch = false;
export let blScrollbar = false;
export let blAndroid = false;
export let blIOS = false;
export let blIpad = false;
export let blSafari = false;
export let blFirefox = false;
export let blIE = false;
export let blEdge = false;
export let blWindows = false;
export let blChrome = false;
export let blRetina = false;

// also change in css
export const transitionFast = 200;
export const transitionDefault = 400;
export const transitionSlow = 800;

export const maxContent = 1000;
export const xl = 1600;
export const large = 1400;
export const small = 1200;
export const tablet = 1023;
export const phablet = 800;
export const mobile = 599;
export const mobileSmall = 374;
export const mobileHorizontal = 440;

export const mqMaxContent = window.matchMedia('(min-width: ' + maxContent + 'px)');
export const mqXl = window.matchMedia('(max-width: ' + xl + 'px)');
export const mqLarge = window.matchMedia('(max-width: ' + large + 'px)');
export const mqSmall = window.matchMedia('(max-width: ' + small + 'px)');
export const mqTablet = window.matchMedia('(max-width: ' + tablet + 'px)');
export const mqPhablet = window.matchMedia('(max-width: ' + phablet + 'px)');
export const mqMobile = window.matchMedia('(max-width: ' + mobile + 'px)');
export const mqMobileSmall = window.matchMedia('(max-width: ' + mobileSmall + 'px)');
export const mqMobileHorizontal = window.matchMedia('(max-height: ' + mobileHorizontal + 'px)');

export const mqDark = window.matchMedia('(prefers-color-scheme: dark)');
export const mqPortrait = window.matchMedia('(orientation: portrait)');

export const gutterLarge = 32;
export const gutterMedium = 24;
export const gutterSmall = 12;

// local variables ------------------------------------------------------------

// init vars function ---------------------------------------------------------
const initVars = () => {
  // console.log('initVars');
  const ua = navigator.userAgent;

  // check for touch
  if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
    document.body.classList.add('body--touch');
    blTouch = true;
  }

  // check for scrollbar
  if (realSize().width != window.innerWidth) {
    document.body.classList.add('body--scrollbar');
    blScrollbar = true;
  }

  // check for android
  if (ua.toLowerCase().indexOf('android') > -1) {
    document.body.classList.add('body--android');
    blAndroid = true;
  }

  // check for ios
  if (
    ['iPad', 'iPhone', 'iPod'].includes(navigator.platform)
    || (ua.includes('Mac') && 'ontouchend' in document)
  ) {
    document.body.classList.add('body--ios');
    blIOS = true;
  }

  // check for ipad
  if (ua.indexOf('iPad') > -1) {
    document.body.classList.add('body--ipad');
    blIpad = true;
  }

  // check for safari
  if (ua.indexOf('Safari') != -1 && ua.indexOf('Chrome') == -1) {
    document.body.classList.add('body--safari');
    blSafari = true;
  }

  // check for firefox
  if (ua.toLowerCase().indexOf('firefox') > -1) {
    document.body.classList.add('body--firefox');
    blFirefox = true;
  }

  // check for ie
  if (window.document.documentMode) {
    document.body.classList.add('body--ie');
    blIE = true;
  }

  // check for edge
  if (ua.indexOf('Edge') > -1) {
    document.body.classList.add('body--edge');
    blEdge = true;
  }

  // check for windows
  if (navigator.platform.indexOf('Win') > -1) {
    document.body.classList.add('body--windows');
    blWindows = true;
  }

  // check for chrome
  if (/Chrome/.test(ua) && /Google Inc/.test(navigator.vendor)) {
    document.body.classList.add('body--chrome');
    blChrome = true;
  }

  // check for blend modes
  if ('CSS' in window && 'supports' in window.CSS) {
    let support = window.CSS.supports('mix-blend-mode', 'soft-light');
    support = support ? 'body--mix-blend-mode' : 'body--no-mix-blend-mode';
    document.body.classList.add(support);
  }

  // check for retina
  const mq = window.matchMedia(
    'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)',
  );
  if ((mq && mq.matches) || window.devicePixelRatio > 1) {
    document.body.classList.add('body--retina');
    blRetina = true;
  }
};

// used to get the realSize width including the scrollbar
// this is needed to get the same width as the mediaqueries in css
const realSize = () => {
  let doc = window;
  let name = 'inner';

  if (!('innerWidth' in window)) {
    name = 'client';
    doc = document.documentElement || document.body;
  }

  return {
    width: doc[name + 'Width'],
    height: doc[name + 'Height'],
  };
};

// DOMContentLoaded event handler
document.addEventListener('DOMContentLoaded', initVars);
