// Switcher V1.0
// used for resizing a parent when the content has switched to a new element
// also copy _switcher.scss

// init: switcher = new Switcher();

// usage: 
// <button data-switcher-target="name" data-nr="0"></button>
// <div class="switcher" data-switcher="name">
//   <div class="switcher__item" data-switcher="name" data-nr="0">
//   <!-- content -->
//   </div><!-- switcher__item -->
// </div><!-- switcher -->
// add switcher__item--hidden to start an element hidden
// there can be multiple switcher elements with the same name on the page, to replace different content at the same time

// requires properties:
// none

// optional properties:
// duration = 400: set to transition in ms, set to --switcher-duration
// afterFadeOutCallback = '': called after fading out
// afterResizeCallback = '': called after resizing when all is done

// changelog
// 1.0 initial version

export default class Switcher {
  constructor({
    duration = 400,
    afterFadeOutCallback = '',
    afterResizeCallback = '',
  } = '') {
    // console.log('Switcher');

    // user variables -----------------------------------------------
    this.duration = duration;
    this.afterFadeOutCallback = afterFadeOutCallback;
    this.afterResizeCallback = afterResizeCallback;

    // scope variables ----------------------------------------------
    let blockResizeObserver = false;
    
    // functions ----------------------------------------------------
    const setResize = () => {
      // console.log('setResize');
      const switcherEls = document.querySelectorAll('.switcher');
      switcherEls.forEach(switcherEl => {
        const action = switcherEl.getAttribute('data-switcher');
        const activeItemEl = switcherEl.querySelector(`.switcher__item[data-switcher="${action}"]:not(.switcher__item--hidden)`);
        if (activeItemEl) {
          const activeTextHeight = activeItemEl.offsetHeight;
          // console.log({activeTextHeight});
          switcherEl.style.setProperty('--switcher-scroll-height', activeTextHeight + 'px');
        } else {
          switcherEl.style.setProperty('--switcher-scroll-height', 0 + 'px');
        }
      }); 
    };

    const observerHandler = () => {
      // console.log('observerHandler');
      // after possible site specific resize calculations
      if (!blockResizeObserver) setTimeout(setResize, 100);
    };


    const setSwitcher = (target, nr) => {
      const switcherEls = document.querySelectorAll(`.switcher[data-switcher="${target}"]`);
    
      // console.log({target, nr, switcherEls});

      blockResizeObserver = true;
    
      switcherEls.forEach(switcherEl => {
        switcherEl.classList.add('switcher--faded-out');
    
        setTimeout(() => {
          const itemEls = switcherEl.querySelectorAll(`.switcher__item[data-switcher="${target}"]`);
          itemEls.forEach(itemEl => {
            itemEl.classList.add('switcher__item--hidden');
          });
    
          const itemEl = switcherEl.querySelector(`.switcher__item[data-switcher="${target}"][data-nr="${nr}"]`);
          // console.log({itemEl});
          if (itemEl) itemEl.classList.remove('switcher__item--hidden');
    
          switcherEl.classList.remove('switcher--faded-out');
    
          switcherEl.classList.add('switcher--resizing'); // resize holder to new size
    
          requestAnimationFrame(setResize); // wuut
          
          if (typeof this.afterFadeOutCallback == 'function') {
            this.afterFadeOutCallback();
          } 
        }, this.duration);
    
        setTimeout(() => {
          switcherEl.classList.remove('switcher--resizing');
          blockResizeObserver = false;

          if (typeof this.afterResizeCallback == 'function') {
            this.afterResizeCallback();
          }
        }, this.duration * 2);
      });
    }

    const elFromEvent = (selector, event) => {
      const {target} = event;
      const ancestor = target.closest(selector);
      return ancestor || target.matches(selector);
    };

    // listeners ----------------------------------------------------
    // click listener --------------------------------
    document.body.addEventListener('click', event => {
      let el = elFromEvent('button[data-switcher-target], input[data-switcher-target]', event);
      if (el) {
        const target = el.getAttribute('data-switcher-target');
        const nr = el.getAttribute('data-nr');

        setSwitcher(target, nr);
      }
    });

    // select change listerer ------------------------
    document.body.addEventListener('change', event => {
      // switcher!
      let el = elFromEvent('select[data-switcher-target]', event);
      if (el) {
        const target = el.getAttribute('data-switcher-target');
        const nr = el.value;
        setSwitcher(target, nr);
      }
    });
    
    window.visualViewport.addEventListener('resize', setResize);
    setResize(); // set initial size

    // add an observer, so the resizer is called when body size changes
    const el = document.querySelector('body');
    new ResizeObserver(observerHandler).observe(el);
  }
}


