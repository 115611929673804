// do this when loading everything
// so after loading all (non lazyloaded) images

// imports --------------------------------------------------------------------
import * as Resize from "./4-resize.js";
import * as Scroll from "./5-scroll.js";

// exported variables ---------------------------------------------------------

// local variables ------------------------------------------------------------

// page loaded function -------------------------------------------------------
const loadedPage = () => {
  // sets all the pages after loading
  // console.log('loadedPage');

  // into frame so it's not called before onready (which Firefox and Safari do)
  requestAnimationFrame(() => {
    // because things might've changed after font/img loading
    Resize.setResize();
    Scroll.setScroll();

    // scroll to hash?
    const hash = window.location.hash.substring(1);
    const el = document.getElementById(hash);

    if (hash && el) {
      const rect = el.getBoundingClientRect();
      const top = rect.top + Scroll.scrollTop;
      const center = top - (Resize.windowHeight / 2);
      Scroll.animateScrollTop(center);
    }
  });
};

// document ready listener
window.addEventListener('load', loadedPage);
